<template>
  <div>
    <Notification />
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
import Notification from '@/components/Notification'

export default {
  name: 'BasicLayout',
  components: {
    Notification
  }
}
</script>
